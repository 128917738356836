// src/components/CategorySelector.js
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCategory } from '../features/chatSlice';
import './CategorySelector.css';

const CategorySelector = () => {
	const categories = useSelector((state) => state.chat.categories);
	const selectedCategory = useSelector((state) => state.chat.selectedCategory);
	const dispatch = useDispatch();

	const handleCategoryClick = (category) => {
		dispatch(selectCategory(category.id));
	};

	return (
		<div className="category-selector card shadow-sm">
			<div className="card-body">
				<h5 className="card-title">Select a Category</h5>
				<div className="category-buttons">
					{categories.map((category) => (
						<button
							key={category.id}
							className={`btn btn-outline-primary m-2 ${selectedCategory === category.id ? 'active' : ''}`}
							onClick={() => handleCategoryClick(category)}
						>
							{category.name}
						</button>
					))}
				</div>
			</div>
		</div>
	);
};

export default CategorySelector;
