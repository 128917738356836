// src/App.js
import React from 'react';
import { useSelector } from 'react-redux';
import Chat from './Chat';
import CategorySelector from './CategorySelector';

function Home() {
    const selectedCategoryId = useSelector((state) => state.chat.selectedCategory);
    const selectedCategory = useSelector((state) =>
        state.chat.categories.find((category) => category.id === selectedCategoryId)
    );

    return (
        <div className="app container mt-4 d-flex flex-column min-vh-100">
            <div className='alert alert-info'>
                <h4 className="">Pick a topic and ask a question about it.</h4>
            </div>
            <CategorySelector />
            {selectedCategory && (
                <>                    
                    <Chat category={selectedCategory.name} />
                </>
            )}
        </div>
    );
}

export default Home;
