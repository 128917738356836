// src/components/Chat.js
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addMessage, sendMessage } from '../features/chatSlice';
import './Chat.css';

const Chat = ({ category }) => {
    const [input, setInput] = useState('');
    const { messages, loading, error } = useSelector(state => state.chat);
    const dispatch = useDispatch();
    const endOfMessagesRef = useRef(null);
    const inputRef = useRef(null);

    const handleSend = () => {
        if (input.trim()) {
            dispatch(addMessage({ role: 'user', content: input }));
            setInput('');
            dispatch(sendMessage());
        }
    };

    useEffect(() => {
        endOfMessagesRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    useEffect(() => {
        // Adjust the height of the input field based on its content
        if (inputRef.current) {
            inputRef.current.style.height = 'auto'; // Reset height
            inputRef.current.style.height = `${inputRef.current.scrollHeight}px`;
        }
    }, [input]);

    // Define the formatResponseText function
    // const formatResponseText = (responseText) => {
    //     // The function implementation as provided
    //     // let formattedText = responseText.replace(/###\s(.+?)(?=\s###|$)/g, '<h3>$1</h3>');
    //     let formattedText = responseText.replace(/####\s(.+?)(?=\n|$)/g, '<h4>$1</h4>');
    //     formattedText = responseText.replace(/###\s(.+?)(?=\n|$)/g, '<h3>$1</h3>');
    //     formattedText = formattedText.replace(/##\s(.+?)(?=\s##|$)/g, '<h2>$1</h2>');
    //     formattedText = formattedText.replace(/#\s(.+?)(?=\s#|$)/g, '<h1>$1</h1>');
    //     formattedText = formattedText.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');
    //     formattedText = formattedText.replace(/\*(.+?)\*/g, '<em>$1</em>');
    //     formattedText = formattedText.replace(/`(.+?)`/g, '<code>$1</code>');
    //     formattedText = formattedText.replace(/^\s*> (.+)$/gm, '<blockquote>$1</blockquote>');
    //     formattedText = formattedText.replace(/^\d+\.\s(.+)$/gm, '<li>$1</li>');
    //     formattedText = formattedText.replace(/(<li>[\s\S]+<\/li>)/g, '<ol>$1</ol>');
    //     formattedText = formattedText.replace(/^- (.+)$/gm, '<li>$1</li>');
    //     formattedText = formattedText.replace(/(<li>[\s\S]+<\/li>)/g, '<ul>$1</ul>');
    //     formattedText = formattedText.replace(/\[(\d+)\]/g, '<sup>[$1]</sup>');
    //     formattedText = formattedText.replace(/^-{3,}|^\*{3,}/gm, '<hr>');
    //     return formattedText;
    // }

    const formatResponseText = (responseText) => {
        // Replace primary headings (#) with <h1> tags
        let formattedText = responseText.replace(/(^|\n)#\s(.+?)(?=\n|$)/g, '<h1>$2</h1>');
    
        // Replace sub-headings (##) with <h2> tags
        formattedText = formattedText.replace(/(^|\n)##\s(.+?)(?=\n|$)/g, '<h2>$2</h2>');
    
        // Replace section headings (###) with <h3> tags
        formattedText = formattedText.replace(/(^|\n)###\s(.+?)(?=\n|$)/g, '<h3>$2</h3>');
    
        // Replace subsection headings (####) with <h4> tags
        formattedText = formattedText.replace(/(^|\n)####\s(.+?)(?=\n|$)/g, '<h4>$2</h4>');
    
        // Replace bold text (**text**) with <strong> tags
        formattedText = formattedText.replace(/\*\*(.+?)\*\*/g, '<strong>$1</strong>');
    
        // Replace italic text (*text*) with <em> tags
        formattedText = formattedText.replace(/\*(.+?)\*/g, '<em>$1</em>');
    
        // Replace inline code (`code`) with <code> tags
        formattedText = formattedText.replace(/`(.+?)`/g, '<code>$1</code>');
    
        // Replace blockquotes (>) with <blockquote> tags
        formattedText = formattedText.replace(/^\s*> (.+)$/gm, '<blockquote>$1</blockquote>');
    
        // Replace ordered list items (1., 2., etc.) with <ol><li> tags
        formattedText = formattedText.replace(/^\d+\.\s(.+)$/gm, '<li>$1</li>');
        formattedText = formattedText.replace(/(<li>[\s\S]+<\/li>)/g, '<ol>$1</ol>');
    
        // Replace unordered list items (- text) with <ul><li> tags
        formattedText = formattedText.replace(/^- (.+)$/gm, '<li>$1</li>');
        formattedText = formattedText.replace(/(<li>[\s\S]+<\/li>)/g, '<ul>$1</ul>');
    
        // Replace superscript annotations ([0], [1]) with <sup>[0]</sup> tags
        formattedText = formattedText.replace(/\[(\d+)\]/g, '<sup>[$1]</sup>');
    
        // Replace horizontal rules (--- or ***) with <hr> tags
        formattedText = formattedText.replace(/^-{3,}|^\*{3,}/gm, '<hr>');
    
        // Convert double newlines to <p> tags to create paragraphs
        formattedText = formattedText.replace(/\n\n/g, '</p><p>');
    
        // Wrap the entire content in <p> tags
        formattedText = `<p>${formattedText}</p>`;
    
        return formattedText;
    }
    

    return (
        <div className="chat-box">
            <div className="chat-header">
                <h5>Chat - {category}</h5>
            </div>
            <div className="chat-messages">
                {messages
                    .map((msg, index) => (
                        <div
                            key={index}
                            className={`chat-message ${msg.role === 'user' ? 'user-message' : 'system-message'}`}
                        >
                            {msg.role === 'assistant'
                                ? <div dangerouslySetInnerHTML={{ __html: formatResponseText(msg.content) }} />
                                : <span className="message-text">{msg.content}</span>}
                        </div>
                    ))}
                {loading && <div className="chat-loading">Loading...</div>}
                {error && <div className="chat-error">Error: {error}</div>}
                <div ref={endOfMessagesRef} /> {/* Reference to scroll to */}
            </div>
            <div className="chat-input">
                <div className="input-group">
                    {/* <textarea
                        ref={inputRef}
                        value={input}
                        onChange={(e) => setInput(e.target.value)}
                        className="form-control"
                        placeholder="Type your message..."
                        disabled={loading}
                    /> */}
                    <input
                        ref={inputRef}
                        type="text"
                        value={input}
                        className="form-control"
                        onChange={(e) => setInput(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSend()}
                        disabled={loading} // Disable input when loading
                        placeholder="Type your question here..."
                    />
                    <button onClick={handleSend} disabled={loading} className="btn btn-primary">
                        Send
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Chat;
