// src/features/chatSlice.js
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

export const sendMessage = createAsyncThunk('chat/sendMessage', async (args, {getState}) => {
    const response = await axios.post('https://mnaaiapi.clavis.digital/chat', {
        vectorId : getState().chat.selectedCategory, 
        assistantId: getState().chat.assistantID,
        chatContext: getState().chat.messages[getState().chat.messages.length - 1],
        threadId: getState().chat.threadId
    });
    return response.data;
});

const initialCategories = [
    { id: 'vs_iYFE8tpUbGQ3vUSREmdQoQTQ', name: 'Acts' },
    { id: 'vs_rN2MubEtafdeT2dXahmobQjD', name: 'Bills' },
    { id: 'vs_Q7xz970sNUjqdrAw2HFvwqh7', name: 'Hansard' },
    { id: 'vs_nTbmaA7mZt0NolqGG8O9j7Pj', name: 'OrderPage' },
    { id: 'vs_o0CaVifeVaKzmb4JMJoU7uhi', name: 'PNQ' },
    { id: 'vs_ZRsaVON0vTw41WpooINTb0wb', name: 'PQ' },
];

const initialMessages = [
    {
        role: "assistant",
        content: "Hello! How can I help you today?",
    }
];

const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        messages: initialMessages,
        categories: initialCategories,
        selectedCategory: null,
        threadId: null,
        loading: false,
        error: null,
        assistantID: 'asst_iZ7ByNxxSCsc76qcX0gDttyC'
    },
    reducers: {
        addMessage: (state, action) => {
            state.messages.push(action.payload);
        },
        selectCategory: (state, action) => {
            state.selectedCategory = action.payload;
            state.messages = initialMessages;
            state.threadId = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendMessage.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(sendMessage.fulfilled, (state, action) => {
                state.loading = false;
                console.log(action.payload);
                // Handle successful response, e.g., add message to state
                state.messages.push({ role: 'assistant', content: action.payload.response.value });
                state.threadId = action.payload.threadId;
            })
            .addCase(sendMessage.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            });
    },
});

export const { addMessage, selectCategory } = chatSlice.actions;
export default chatSlice.reducer;
